import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const PayrollLoan = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 d-flex align-items-center' role='img' aria-label='Cliente Inter Empresas caminhando pela rua enquanto acessa seu Super App'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5 offset-md-6 offset-xl-7'>
            <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-white fw-500 mb-3'>
              Empréstimo Consignado Privado: ágil, prático e com taxas que cabem no seu bolso
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-white'>
              Ofereça benefícios de crédito e conhecimento sobre educação financeira para seus colaboradores, sem custos na folha de pagamento
            </p>
            <a
              title='Saber mais'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-2'
              href='/empresas/consignado-privado/'
              target='_blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  element_action: 'click button',
                  element_name: 'Saber mais',
                  section_name: 'Empréstimo Consignado Privado: ágil, prático e com taxas que cabem no seu bolso',
                  redirect_url: `${window.location.origin}/empresas/consignado-privado/`,
                })
              }}
            >
              Saber mais
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default PayrollLoan
