import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import { Section, Card } from './style'

import cashFlowJSON from '../assets/data/cashFlow.json'

type cashFlowProps = {
  title: string;
  description: string;
}

const CashFlow = () => {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-md-6 d-flex justify-content-center order-md-last mb-3'>
            <ImageWebp
              arrayNumbers={[ 288, 334, 452, 516 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/creditopj-dobra7/image.webp'
              altDescription='Um homem falando no celular enquanto segura em uma caixa. Ao fundo vemos uma caixa e outras caixas'
            />
          </div>
          <div className='col-12 col-md-6 mt-3'>
            <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 fw-500 mb-3'>
              Ramo imobiliário simplificado: seus imóveis podem ajudar a garantir fluxo de caixa
            </h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='col-12'>
              <DefaultCarousel
                sm={{ items: 1, partialVisibilityGutter: 20 }}
                md={{ items: 3 }}
                lg={{ items: 3 }}
                xl={{ items: 3 }}
              >
                {
                  cashFlowJSON.map((item: cashFlowProps) => (
                    <Card className='mr-3' key={item?.title}>
                      <div className='px-3 py-4' key={item.title}>
                        <h3 className='fs-20 fs-xl-24 lh-24 lh-xl-28'>{item.title}</h3>
                        <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-20 lh-xl-22'>{item.description}</p>
                      </div>
                    </Card>
                  ))
                }
              </DefaultCarousel>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CashFlow
